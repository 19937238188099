import React from "react";

const Footer = (props) => {
    return (
        <footer className="background--black">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="footer__contact col-12 col-md-4 col-lg-2 mb-6">
                        <a href="mailto:post@mekle.no">
                            post@mekle.no
                        </a>
                        <a href="tel:(+47)22444100">
                            (+47) 22 44 41 00
                        </a>
                    </div>

                    <div className="col-12 col-lg-2 hide-md-down"/>

                    <div className="footer__links col-12 col-md-4 col-lg-2 mb-5">
                        <a href="https://mekle.no/slik-fungerer-det/">
                            Slik fungerer det
                        </a>
                        <a href="https://mekle.no/vare-meklere/">
                            Våre meklere
                        </a>
                        <a href="https://mekle.no/hvorfor-mekle/">
                            Hvorfor Mekle?
                        </a>
                        <a href="https://mekle.no/ofte-stilte-sporsmal/">
                            FAQ
                        </a>
                        <a href="https://mekle.no/kontaktet-av-oss/">
                            Kontaktet av oss
                        </a>
                    </div>

                    <div className="footer__links col-12 col-md-4 col-lg-2">
                        <a href="https://mekle.no/om-oss/">
                            Om Mekle
                        </a>
                        <a href="https://mekle.no/aktuelt/">
                            Aktuelt
                        </a>
                        <a href="https://mekle.no/personvernerklaering">
                            Personvernerklæring
                        </a>
                        <a href="https://mekle.no/#">
                            Cookies
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
