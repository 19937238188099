import React, { useEffect, useLayoutEffect, useState } from "react";
import useForm from "./useForm";
import { get } from "lodash/object";

const useStringInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const [mask, setMask] = useState(true);
    const [dynamic, setDynamic] = useState("");
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "stringInput";
    const elementId = `useStringInput${key}`;
    const context = props?.context;
    const value = context != null ? get(context?.state, key) : values[key];

    // TODO temporary band-aid fix to placeholder attribute not updating itself based on translation string.
    useEffect(() => {
        let el = document.getElementById(elementId);
        el?.setAttribute?.("placeholder", props?.placeholder ?? "")
    }, []);

    useLayoutEffect(() => {
        if (value?.length > 0 || Object.keys(value ?? {})?.length > 0) {
            if (dynamic?.length === 0) {
                setDynamic(" input__container--with-value")
            }
        } else {
            setDynamic("");
        }
    }, [value]);

    const handleChangeMiddleman = (e) => {
        handleChange(e)
        isValid(e?.target?.value);
    }

    const setValue = (value) => {
        forceValue(key, value);
    };

    const setInvalidity = (message) => {
        let item = document.getElementById(elementId);
        item?.setCustomValidity?.(message);
    }

    const isValid = (v = null) => {
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;

        if (props?.onValidation != null) {
            let response = props?.onValidation?.(item, _value);
            if (response === true || response === false) return response;
            return true;
        }

        if ((rule?.required ?? true) && (value?.length ?? 0) === 0) {
            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const render = () => {
        return (
            <div className={ "input__container input__container--default" + dynamic }>
                { props?.label &&
                <label htmlFor={elementId}>
                    { props?.label }
                </label>
                }
                <input
                    id={ elementId }
                    type={ (rule?.password && mask) ? "password" : "text" }
                    name={ key }
                    placeholder={ props?.placeholder ?? "" }
                    value={ value ?? "" }
                    onChange={ props?.onChange ?? handleChangeMiddleman}
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    minLength={ rule?.minLength }
                    maxLength={ rule?.maxLength }
                    required={ rule?.required ?? true }
                    className={ props?.class }
                    autoComplete={ rule?.autoComplete ?? "name" }
                    disabled={ rule?.disabled ?? false }
                />
            </div>
        );
    };

    return {
        value, render, isValid, setValue, setInvalidity,
    };
};

export default useStringInput;
