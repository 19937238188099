import React, { useEffect, useLayoutEffect, useState } from "react";
import examples from "libphonenumber-js/examples.mobile.json";
import parsePhoneNumber, {
    AsYouType, isValidPhoneNumber,
    getExampleNumber, getCountryCallingCode,
    validatePhoneNumberLength
} from "libphonenumber-js";
import useForm from "@/hooks/Form/useForm";
import { useTranslation } from "react-i18next";
import { get } from "lodash/object";

const usePhoneInput = (props) => {
    const [t] = useTranslation();

    const [dynamic, setDynamic] = useState("");
    const { values, forceValue } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "phoneInput";
    const elementId = `usePhoneInput${key}`;
    const context = props?.context;
    const value = context != null ? get(context?.state, key) : values[key];
    const IS_SERVER = typeof window === "undefined";

    useEffect(() => {
        if (IS_SERVER) return;
        isValid();
    }, []);

    useLayoutEffect(() => {
        if (value?.length > 0 || Object.keys(value ?? {})?.length > 0) {
            if (dynamic?.length === 0) {
                setDynamic(" input__container--with-value")
            }
        } else {
            setDynamic("");
        }
    }, [value]);

    const isValid = (v = null) => {
        if (IS_SERVER) return true;
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;

        if ((rule?.required ?? true)) {
            if ((_value?.country?.length ?? 0) === 0) {
                item?.setCustomValidity?.(t("_errors.input.phone.required"));
                return false;
            } else {
                if (!isValidPhoneNumber(_value?.number ?? "", _value?.country ?? "")) {
                    item?.setCustomValidity?.(t("_errors.input.phone.invalid-format"));
                    return false;
                }
            }
        }

        if (_value?.number?.length > "92929292".length) {
            item?.setCustomValidity?.(t("_errors.input.phone.invalid-format"));
            return false;
        }

        if (_value == null || _value?.number?.length < 4) {
            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const setValue = (v, extra = {}) => {
        v = (v ?? "").replace(/\D/g, "");
        // v = v.replace(/^(47)/ , "");

        let _value = {
            "e.164": `+47${v}`,
            country: "NO",
            dial_code: "47",
            number: v,
        };

        // let matches = _value.number.match(/47/g);
        if (_value?.number?.length === "92929292".length + 2/* || matches?.length === 2*/) {
            _value.number = _value.number.replace(/^(47)/ , "");
            // _value.number = _value.number.replace(/47/g , "")
            // if (matches?.length === 2) {
            //     _value.number = "47" + _value.number
            // }
        }

        while (_value?.number?.length > "92929292".length/*validatePhoneNumberLength(_value.number, _value.country) === "TOO_LONG"*/) {
            _value.number = _value.number.substr(0, _value.number.length - 1);
        }

        forceValue(key, _value);
        isValid(_value);
    };

    const displayValue = () => {
        return (new AsYouType("NO").input(value?.number ?? ""));
    };

    const e164 = () => {
        return displayValue();
    }

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const render = () => {
        return (
            <div className={ "input__container input__container--phone" + dynamic }>
                <div className="input__prefix input__prefix--phone"/>
                <label htmlFor={ elementId }>
                    { props?.label ?? "Telefonnummer" }
                </label>
                <input
                    id={ elementId }
                    type="tel"
                    name={ key }
                    placeholder={ props?.placeholder }
                    autoComplete="tel"
                    value={ displayValue() }
                    onChange={ onChange }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    className={ props?.class + (isValid() ? "" : " invalid") }
                    required={ rule?.required ?? true }
                    disabled={ rule?.disabled ?? false }
                />
            </div>
        );
    }

    return {
        value, render, isValid, setValue, e164,
    };
};

export default usePhoneInput;

