import React, { useEffect, useLayoutEffect, useState } from "react";
import useForm from "@/hooks/Form/useForm";
import { get } from "lodash/object";

const useEmailInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const [dynamic, setDynamic] = useState("");
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "email";
    const elementId = `useEmailInput${key}`;
    const context = props?.context;
    const value = context != null ? get(context?.state, key) : values[key];

    // TODO temporary band-aid fix to placeholder attribute not updating itself based on translation string.
    useEffect(() => {
        let el = document.getElementById(elementId);
        el?.setAttribute?.("placeholder", props?.placeholder ?? "")
    }, []);

    useLayoutEffect(() => {
        if (value?.length > 0 || Object.keys(value ?? {})?.length > 0) {
            if (dynamic?.length === 0) {
                setDynamic(" input__container--with-value")
            }
        } else {
            setDynamic("");
        }
    }, [value]);

    const isValid = (v = null) => {
        let _value = v != null ? v : value;
        if (typeof value === "object") {
            _value = value.address;
        }
        if (_value == null) _value = "";

        if (_value.length < 5) return false;
        let atOccurrences = _value.match(/[@]/g)?.join("");
        if (atOccurrences?.length > 1) return false;

        let atIndex = _value.indexOf("@");
        if (atIndex === -1) return false;
        if (atIndex === 0) return false;
        if (atIndex + 1 === _value.length) return false;

        let dotIndex = _value.lastIndexOf(".");
        if (dotIndex === -1) return false;
        if (dotIndex + 1 === _value.length) return false;
        if (dotIndex < atIndex) return false;

        return true;
    }

    const setValue = (value) => {
        forceValue(key, value);
    };

    const handleChangeMiddleman = (e) => {
        let target = e.target.value;
        if (value?.id != null || value?.address != null) {
            let target = value;
            target.address = e?.target?.value;
        }

        setValue(target);
        // isValid(e?.target?.value);
    };

    const displayValue = () => {
        if (value?.id != null || value?.address != null) {
            return value?.address ?? "";
        }

        return value ?? "";
    };

    const render = () => {
        return (
            <div className={ "input__container input__container--default" + dynamic }>
                { props?.label &&
                    <label htmlFor={elementId}>
                        {props?.label}
                    </label>
                }
                <input
                    id={elementId}
                    name={key}
                    type="email"
                    placeholder={props?.placeholder ?? "john@citizen.com"}
                    autoComplete="email"
                    value={ displayValue() }
                    onChange={ props?.onChange ?? handleChangeMiddleman }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    required={ rule?.required ?? true}
                    className={ props?.class }
                    disabled={ rule?.disabled ?? false }
                />
            </div>
        );
    };

    return {
        value, render, setValue, isValid,
    };
};

export default useEmailInput;
